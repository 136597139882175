import Translation from "src/translations/locales/translation";

import { WithTFunction } from "src/types/WithTFunction";
import { Component, ReactNode } from "react";
import { withTranslation } from "react-i18next";
import { FooterProps, FooterState } from "./index.interfaces";
import { SafeLink } from "../safe-link";
import { RoutesEnum } from "src/enums/Routes";

class FooterComponent extends Component<
  WithTFunction<FooterProps>,
  FooterState
> {
  render(): ReactNode {
    const { t, isFooterForShopScreen } = this.props;

    return (
      <footer className="w-full bg-footer-background text-white text-center h-[150px] py-12 px-8 tablet:py-12 desktop:py-16 desktop:px-28 flex flex-col tablet:flex-row gap-4 tablet:gap-8 justify-center items-center">
        {/* privacy policy */}
        <SafeLink
          className="w-fit text-base hover:opacity-75"
          key={`footer-quick-access-privacyPolicy`}
          to={
            isFooterForShopScreen === true
              ? "/shop/privacy-policy"
              : RoutesEnum.privacyPolicy
          }
        >
          <p className="text-sm transition-colors w-fit">
            {t(Translation.screens.privacyPolicy.header.title).toUpperCase()}
          </p>
        </SafeLink>
        {/* terms of service */}
        <SafeLink
          className="w-fit text-base hover:opacity-75"
          key={`footer-quick-access-termsOfService`}
          to={RoutesEnum.termsOfService}
        >
          <p className="text-sm transition-colors w-fit">
            {t(Translation.screens.termsOfService.header.title).toUpperCase()}
          </p>
        </SafeLink>
        {/* imprint */}
        <SafeLink
          className="w-fit !text-base hover:opacity-75"
          key={`footer-quick-access-imprint`}
          to={RoutesEnum.imprint}
        >
          <p className="text-sm transition-colors w-fit">
            {t(Translation.screens.imprint.header.title).toUpperCase()}
          </p>
        </SafeLink>
        {/* <FooterQuickAccess /> 
         <PaymentPartners
          icons={[
            <GooglePayIcon className="w-16" />,
            <ApplePayIcon className="w-16" />,
            <PayPalIcon className="w-16" />,
            <VisaCardIcon className="w-16" />,
            <MasterCardIcon className="w-16" />,
            <KlarnaPayIcon className="w-16" />,
          ]}
          copyRightText={t(Translation.common.footer.copyRightText)}
        /> */}
      </footer>
    );
  }
}

export default withTranslation()(FooterComponent);
