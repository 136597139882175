import Translation from "src/translations/locales/translation";
import googleIcon from "src/assets/images/google-icon.png";

import { useState } from "react";
import { auth } from "src/firebase";
import { useTranslation } from "react-i18next";
import { Image } from "src/shared-components/image";
import { useGoogleLogin } from "@react-oauth/google";
import { UserController } from "src/components/user";
import { signInWithCustomToken } from "firebase/auth";
import { ErrorHandler } from "src/abstracts/handleError";
import { GoogleProviderButtonProps } from "./index.interfaces";
import { FirebaseAuthErrorHandler } from "src/utils/FirebaseAuthErrorHandler";

export function GoogleProviderButton(props: GoogleProviderButtonProps) {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      await onSuccessSignInWithGoogle(codeResponse.access_token);
    },
    onError: (e) => {
      ErrorHandler.displayError(e);
      setIsLoading(false);
    },
    error_callback: (e) => {
      ErrorHandler.displayError(e);
      setIsLoading(false);
    },
    scope: "email profile",
  });

  const onSuccessSignInWithGoogle = async (accessToken: string) => {
    try {
      const res = await UserController.signInWithGoogleAccessToken({
        accessToken: accessToken,
      });

      await signInWithCustomToken(auth, res.authToken);

      window.location.href = props.callbackUrl;
    } catch (e) {
      FirebaseAuthErrorHandler.handle(e, t);
      setIsLoading(false);
    }
  };

  const onClick = async () => {
    setIsLoading(true);
    login();
  };

  return (
    <button
      onClick={onClick}
      disabled={false}
      className="flex items-center justify-center gap-2 py-2 px-4 w-full bg-white rounded-full"
    >
      {isLoading ? (
        <span className="loading loading-spinner" />
      ) : (
        <>
          <Image
            src={googleIcon}
            alt="goole-provider-icon"
            className="w-5 h-5 -translate-y-[1px]"
          />
          <p className="font-new-atten-medium">
            {t(Translation.common.providerButtons.google)}
          </p>
        </>
      )}
    </button>
  );
}

export default GoogleProviderButton;
