export enum RoutesEnum {
  authResponse = "/auth/response",
  home = "/",
  privacyPolicy = "/privacy-policy",
  termsOfService = "/terms-of-service",
  imprint = "/imprint",
  signin = "/sign-in",
  profile = "/profile",
  redeemCode = "/code",
  howToDeleteMyAccount = "/how-to-delete-my-account",
  emailVerificationResponse = "/email-verification-response",
  soundscapes = "/soundscapes/*",
  collections = "/collections/*",
  exercises = "/exercises/*",
  minddrops = "/minddrops/*",
  faq = "/faq",
  forgotPassword = "/forgot-password",
  shop = "/shop",
  paymentResponse = "/payment-response",
}
