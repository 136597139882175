import Translation from "src/translations/locales/translation";

import { t } from "i18next";
import { RoutesEnum } from "src/enums/Routes";
import { useLocation, useNavigate } from "react-router-dom";
import { HeaderComponent } from "src/shared-components/header";
import { CloseCircleIcon, TickCircleIcon } from "src/assets/icons";
import { redirectToAppOrStore } from "src/utils/redirectToAppOrStore";
import { TextWithLineBreaks } from "src/shared-components/text-with-line-breaks";
import { SimpleElevatedButton } from "src/shared-components/form/fields/buttons/simple-elevated-button";

export default function PaymentResponseScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  // access query parameters
  const success: boolean = queryParams.get("success") === "true" ? true : false;

  return (
    <section className="w-full flex flex-col h-[100svh] relative">
      <HeaderComponent />
      <div className="w-full flex-1 flex flex-col items-center justify-center p-6">
        {/* content */}
        <div className="flex flex-col items-center justify-center gap-8">
          {success ? (
            <div className="flex flex-col gap-4 items-center max-w-[356px] text-normal">
              <TickCircleIcon className="stroke-on-background-2 w-20 h-20" />
              {/* text */}
              <TextWithLineBreaks
                text={t(Translation.screens.paymentResponse.success.text)}
                className="text-center"
              />
            </div>
          ) : (
            <div className="flex flex-col gap-4 items-center max-w-[356px] text-normal">
              <CloseCircleIcon className="stroke-on-background-2 w-20 h-20" />
              {/* text */}
              <TextWithLineBreaks
                text={t(Translation.screens.paymentResponse.failed.text)}
                className="text-center"
              />
            </div>
          )}
          {/* button */}
          {success ? (
            <SimpleElevatedButton
              id="shop-payment-response-back-to-app-button"
              text={t(Translation.screens.paymentResponse.openAppButton)}
              onClick={() => redirectToAppOrStore(true)}
            />
          ) : (
            <SimpleElevatedButton
              id="shop-payment-response-back-to-shop-button"
              text={t(Translation.screens.paymentResponse.backToShopButton)}
              onClick={() => {
                navigate(RoutesEnum.shop);
              }}
            />
          )}
        </div>
      </div>
    </section>
  );
}
