import Translation from "src/translations/locales/translation";

import { ReactNode } from "react";
import { auth } from "src/firebase";
import { User } from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { RoutesEnum } from "src/enums/Routes";
import { withTranslation } from "react-i18next";
import { AppError } from "src/abstracts/appError";
import { UserController } from "src/components/user";
import { WithTFunction } from "src/types/WithTFunction";
import { ErrorHandler } from "src/abstracts/handleError";
import { RedeemCodeScreenSectionKey } from "./index.enum";
import { CouponRespository } from "src/components/coupon";
import { DynamicForm } from "src/composable/base-dynamic-form";
import { HeaderComponent } from "src/shared-components/header";
import { SnackbarService } from "src/shared-components/snackbar";
import { ModalDialogComponent } from "src/shared-components/modal-dialog";
import { FirebaseAuthErrorHandler } from "src/utils/FirebaseAuthErrorHandler";
import { CouponResponse } from "src/components/coupon/controller/index.responses";
import { RedeemCodeScreenSuccessSubmit } from "./sections/content/success-submit";
import { RedeemCodeScreenSubmitCodeForm } from "./sections/content/submit-code-form";
import { ButtonOptionStyles } from "src/shared-components/modal-dialog/index.interfaces";

interface RedeemCodeScreenState {
  sectionKey: RedeemCodeScreenSectionKey;
  code: string;
  isLoading: boolean;
  couponRes: CouponResponse | null;
  user: User | null;
  isEmailVerificationModalOpen: boolean;
}

class RedeemCodeScreen extends DynamicForm<
  WithTFunction<{}>,
  RedeemCodeScreenState
> {
  state: Readonly<RedeemCodeScreenState> = {
    sectionKey: RedeemCodeScreenSectionKey.reedemCode,
    code: "",
    isLoading: false,
    couponRes: null,
    user: null,
    isEmailVerificationModalOpen: false,
  };

  componentDidMount(): void {
    auth.onAuthStateChanged((user) => {
      if (user === null) {
        window.location.replace(RoutesEnum.signin);
      } else {
        this.setState({ user });
      }
    });
  }

  onCodeChanged = (code: string) => {
    this.setState({ code });
  };

  onSubmit = async () => {
    const { code } = this.state;

    this.setState({ isLoading: true });
    try {
      const res = await CouponRespository.redeemCode({ code });
      this.setState({ couponRes: res });
      this.onSectionChanged(RedeemCodeScreenSectionKey.success);
    } catch (e) {
      if (e instanceof AppError) {
        // if error has action
        if (e.action === "show_email_verification_modal") {
          this.setState({ isEmailVerificationModalOpen: true });
        } else {
          // if error hasn't action
          ErrorHandler.displayError(e);
        }
      } else if (e instanceof FirebaseError) {
        // if there was a firebase error
        FirebaseAuthErrorHandler.handle(e, this.props.t);
      } else {
        // handle anything else
        ErrorHandler.displayError(e);
      }
    } finally {
      this.setState({ isLoading: false });
    }
  };

  onDisableSubmitButton = () => {
    const { code } = this.state;
    return !code.trim();
  };

  onSectionChanged = (sectionKey: RedeemCodeScreenSectionKey) => {
    this.setState({ sectionKey });
  };

  buildSectionFromSectionKey = (sectionKey: RedeemCodeScreenSectionKey) => {
    const { code, couponRes, user } = this.state;

    switch (sectionKey) {
      case RedeemCodeScreenSectionKey.reedemCode:
        return (
          <RedeemCodeScreenSubmitCodeForm
            code={code}
            onCodeChanged={this.onCodeChanged}
            onSubmit={this.onSubmit}
            onDisableSubmitButton={this.onDisableSubmitButton}
            user={user}
          />
        );
      case RedeemCodeScreenSectionKey.success:
        if (!couponRes) return;
        return <RedeemCodeScreenSuccessSubmit couponRes={couponRes} />;

      default:
        throw new Error("Unknown section key");
    }
  };

  onSendEmailVerification = async () => {
    const { user } = this.state;

    if (!user) return null;

    try {
      const res: string = await UserController.requestVerification(user.email!);
      SnackbarService.showSuccessMessage(res);
    } catch (e) {
      ErrorHandler.displayError(e);
    }
  };

  onIsEmailVerificationModalOpenChanged = () => {
    this.setState({
      isEmailVerificationModalOpen: !this.state.isEmailVerificationModalOpen,
    });
  };

  render(): ReactNode {
    const { t } = this.props;

    const { sectionKey, isEmailVerificationModalOpen } = this.state;

    return (
      <main className="w-full h-full min-h-[100svh] max-h-full flex flex-col items-center relative">
        <HeaderComponent />
        <section className="w-full flex flex-1 justify-center items-center">
          <div className="w-full pb-10 px-12 min-w-[250px] max-w-[600px] flex flex-col gap-8">
            {/* content */}
            {this.buildSectionFromSectionKey(sectionKey)}
          </div>
        </section>

        <ModalDialogComponent
          openController={isEmailVerificationModalOpen}
          onOpenControllerChanged={this.onIsEmailVerificationModalOpenChanged}
          title={t(Translation.general.emailIsNotVerifiedModal.title)}
          dialogText={t(Translation.general.emailIsNotVerifiedModal.dialogText)}
          acceptButton={{
            label: t(
              Translation.general.emailIsNotVerifiedModal.sendLinkButtonText
            ),
            onClick: this.onSendEmailVerification,
            style: ButtonOptionStyles.primary,
          }}
          cancelButton={{
            label: t(
              Translation.general.emailIsNotVerifiedModal.cancelButtonText
            ),
            onClick: () => {},
            style: ButtonOptionStyles.secondary,
          }}
        />
      </main>
    );
  }
}

export default withTranslation()(RedeemCodeScreen);
