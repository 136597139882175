import { create } from "zustand";
import { Stripe } from "./components/stripe";
import { persist } from "zustand/middleware";

interface CartStoreState {
  cart: Stripe[];
  addProduct: (item: Stripe) => void;
  removeProduct: (item: Stripe) => void;
}

export const useCartStore = create<CartStoreState>()(
  persist(
    (set, get) => ({
      cart: [],
      addProduct: (item: Stripe) => {
        const state = get();
        if (!state.cart.find((product) => product.id === item.id)) {
          set({ cart: [...state.cart, item] });
        }
      },
      removeProduct: (item: Stripe) =>
        set((state) => ({
          cart: state.cart.filter((product) => product.id !== item.id),
        })),
    }),
    {
      name: "cart-store",
      onRehydrateStorage: () => (state) => {
        if (state) {
          state.cart = state.cart.map((item) => Stripe.fromJson(item));
        }
      },
    }
  )
);
