import Translation from "src/translations/locales/translation";

import { useTranslation } from "react-i18next";
import { ShopRevocationRightScreenContent } from "./sections/content";

export default function ShopRevocationRightScreen() {
  const { t } = useTranslation();

  return (
    <main className="flex flex-col py-8 px-6 tablet:px-16 tablet:py-12 desktop:px-24 desktop:py-16 gap-16">
      {/* content */}
      <ShopRevocationRightScreenContent
        content={t(Translation.markdowns.shop.screens.revocationRight)}
      />
    </main>
  );
}
