import { ShopHomeScreenProps } from "./index.interfaces";
import { FooterComponent } from "src/shared-components/footer";
import { ShopCardsWrapper } from "src/shared-components/shop-cards-wrapper";
import { ShopCheckoutDisplay } from "src/shared-components/shop-checkout-display";
import { ShopHomeScreenScrollToTopButton } from "./components/scroll-to-top-button";

export default function ShopHomeScreen({ products }: ShopHomeScreenProps) {
  return (
    <section className="w-full flex flex-col mx-auto max-w-[1920px]">
      <div className="flex flex-1 justify-between">
        <ShopCardsWrapper products={products} />
      </div>
      <div className="w-full desktop:hidden">
        <ShopHomeScreenScrollToTopButton />
      </div>
      <div className="w-full desktop:order-1">
        <FooterComponent isFooterForShopScreen={true} />
      </div>
      <ShopCheckoutDisplay />
    </section>
  );
}
