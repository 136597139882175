import Translation from "src/translations/locales/translation";

import { Component, ReactNode } from "react";
import { withTranslation } from "react-i18next";
import { WithTFunction } from "src/types/WithTFunction";
import { Skeleton } from "src/shared-components/skeleton";
import {
  TermsOfServiceScreenHeaderProps,
  TermsOfServiceScreenHeaderState,
} from "./index.interfaces";

class TermsOfServiceScreenHeader extends Component<
  WithTFunction<TermsOfServiceScreenHeaderProps>,
  TermsOfServiceScreenHeaderState
> {
  render(): ReactNode {
    const { t, asOfDate } = this.props;

    return (
      <section className="flex flex-col gap-6">
        <h1 className="text-2xl font-new-atten-bold">
          {t(Translation.screens.termsOfService.header.title).toUpperCase()}
        </h1>
        {asOfDate ? (
          <p className="text-normal opacity-60">
            {t(Translation.screens.termsOfService.header.date, {
              date: new Date(asOfDate).toDateString(),
            })}
          </p>
        ) : (
          <Skeleton className="w-[15rem] h-8" numberOfSkeletons={1} />
        )}
      </section>
    );
  }
}

export default withTranslation()(TermsOfServiceScreenHeader);
