import Translation from "src/translations/locales/translation";

import { useTranslation } from "react-i18next";
import { ShopPrivacyPolicyScreenContent } from "./sections/content";

export default function ShopPrivacyPolicyScreen() {
  const { t } = useTranslation();

  return (
    <main className="flex flex-col py-8 px-6 tablet:px-16 tablet:py-12 desktop:px-24 desktop:py-16 gap-16">
      {/* content */}
      <ShopPrivacyPolicyScreenContent
        content={t(Translation.markdowns.shop.screens.privacyPolicy)}
      />
    </main>
  );
}
