import stepOneImage from "src/assets/images/htdma-step-one.png";
import stepTwoImage from "src/assets/images/htdma-step-two.png";
import stepThreeImage from "src/assets/images/htdma-step-three.png";
import Translation from "src/translations/locales/translation";

import { HeaderComponent } from "src/shared-components/header";
import { Component, ReactNode } from "react";
import { FooterComponent } from "src/shared-components/footer";
import { withTranslation } from "react-i18next";
import { WithTFunction } from "src/types/WithTFunction";
import { Image } from "src/shared-components/image";

interface StepListItem {
  imageSrc: string;
  imageAlt: string;
}

class HowToDeleteMyAccountScreen extends Component<WithTFunction<{}>> {
  private buildStepListItem = (steps: StepListItem[]) => {
    return steps.map((step, i) => (
      <li
        key={step.imageAlt + i}
        className="flex flex-col desktop:flex-row items-center gap-6 desktop:items-start desktop:gap-3"
      >
        <Image src={step.imageSrc} alt={step.imageAlt} />
      </li>
    ));
  };

  private deleteSteps = (): StepListItem[] => {
    return [
      {
        imageSrc: stepOneImage,
        imageAlt: "step-one",
      },
      {
        imageSrc: stepTwoImage,
        imageAlt: "step-two",
      },
      {
        imageSrc: stepThreeImage,
        imageAlt: "step-three",
      },
    ];
  };

  render(): ReactNode {
    const { t } = this.props;

    return (
      <main className="w-full min-h-[100svh] bg-background items-center flex flex-col justify-between">
        {/* header */}
        <HeaderComponent />

        {/* content */}
        <div className="flex flex-col gap-8 py-12 px-8 tablet:p-16 desktop:px-20 tablet:gap-10 desktop:gap-12">
          <h1 className="text-xl text-center font-new-atten-medium px-6">
            {t(Translation.screens.howToDeleteMyAccount.title)}
          </h1>
          <p>{t(Translation.screens.howToDeleteMyAccount.subtitle)}</p>

          {/* step list for mobile and tablet */}
          <ol className="flex flex-col desktop:flex-row justify-center items-center gap-6 list-inside">
            {this.buildStepListItem(this.deleteSteps())}
          </ol>
        </div>

        {/* footer */}
        <FooterComponent />
      </main>
    );
  }
}

export default withTranslation()(HowToDeleteMyAccountScreen);
