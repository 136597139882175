import React from "react";

import { ReactNode, RefObject } from "react";
import { ImprintScreenHeader } from "./sections/header";
import { ImprintScreenContent } from "./sections/content";
import { DynamicSection } from "src/composable/base-dynamic-section";
import { GenericNullable } from "src/types/GenericNullable";
import { ImprintScreenFooter } from "./sections/footer";
import { ErrorHandler } from "src/abstracts/handleError";
import { HeaderComponent } from "src/shared-components/header";
import { FooterComponent } from "src/shared-components/footer";
import { ImprintsRepository, Imprint } from "src/components/imprint";
import { Skeleton } from "src/shared-components/skeleton";

interface ImprintScreenState extends GenericNullable<Imprint> {
  isLoading: boolean;
}

export default class ImprintScreen extends DynamicSection<
  {},
  ImprintScreenState
> {
  mainDivRef: RefObject<HTMLDivElement>;
  _imprintsRepository = new ImprintsRepository();

  state: Readonly<ImprintScreenState> = {
    content: null,
    isLoading: true,
  };

  constructor(props: {}) {
    super(props);
    this.mainDivRef = React.createRef();
  }

  componentDidMount = async () => {
    try {
      this.setState({ isLoading: true });
      await this.getItemsFromServerAndSaveToState();
    } catch (e) {
      ErrorHandler.displayError(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  setToAllStates = (imprint: Imprint) => {
    this.setState({
      content: imprint.content,
    });
  };

  getItemsFromServerAndSaveToState = async () => {
    try {
      this.setState({ isLoading: true });
      const imprint = await this._imprintsRepository.getLatest();
      this.setToAllStates(imprint);
    } catch (e) {
      ErrorHandler.displayError(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  getItemsWithSpecifiedVersion = async (version: string) => {
    try {
      this.setState({ isLoading: true });
      const imprint = await this._imprintsRepository.getByVersion(version);
      this.setToAllStates(imprint);
    } catch (e) {
      ErrorHandler.displayError(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render(): ReactNode {
    const { content, isLoading } = this.state;

    return (
      <main className="flex flex-col min-h-[100svh]">
        {/* header */}
        <HeaderComponent />

        {/* content */}
        <section
          className="flex flex-col flex-1 justify-between py-8 px-6 tablet:px-16 tablet:py-12 desktop:px-24 desktop:py-16 gap-16"
          ref={this.mainDivRef}
        >
          {isLoading ? (
            <div className="flex flex-col gap-8">
              <Skeleton className="h-20" numberOfSkeletons={15} />
            </div>
          ) : (
            <>
              <div className="flex flex-col gap-16">
                <ImprintScreenHeader />
                <ImprintScreenContent content={content} />
              </div>
              <ImprintScreenFooter />
            </>
          )}
        </section>

        {/* footer */}
        <FooterComponent />
      </main>
    );
  }
}
